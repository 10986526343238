import { trigger, transition, animate, style } from '@angular/animations';

export const enterLeaveAnimation: any = trigger('enterLeaveAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '.3s ease-in',
      style({
        opacity: 1,
      }),
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '.2s ease-out',
      style({
        opacity: 0,
      }),
    ),
  ]),
]);
